import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "selected-values"
}
const _hoisted_2 = { class: "me-2" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 1,
  class: "selected-values"
}
const _hoisted_5 = {
  key: 0,
  class: "me-2"
}
const _hoisted_6 = {
  key: 1,
  class: "me-2"
}
const _hoisted_7 = { class: "text-capitalize" }
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.isComplex)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (b, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: i,
            class: "mb-2 me-2 el-tag el-tag--info d-flex align-items-center el-tag--small el-tag--light"
          }, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(b.label), 1),
            _createElementVNode("i", {
              class: "ri-close-line text-white",
              onClick: ($event: any) => (_ctx.removeValue(i)),
              role: "button"
            }, null, 8, _hoisted_3)
          ]))
        }), 128))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (b, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: i,
            class: "mb-2 me-2 el-tag el-tag--info d-flex align-items-center el-tag--small el-tag--light"
          }, [
            (_ctx.checkIsArray(b.date))
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createTextVNode(_toDisplayString(b.value) + " ", 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(b.date, (year, i) => {
                    return (_openBlock(), _createElementBlock("span", { key: i }, _toDisplayString(year) + " " + _toDisplayString(i === 0 && b.date.length > 1 ? _ctx.linkWord + " " : null), 1))
                  }), 128)),
                  _createTextVNode(" " + _toDisplayString(_ctx.scope === "ages" ? "years old" : null), 1)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createTextVNode(_toDisplayString(b.value) + " " + _toDisplayString(_ctx.linkWord) + " ", 1),
                  _createElementVNode("span", _hoisted_7, _toDisplayString(b.date), 1)
                ])),
            _createElementVNode("i", {
              class: "ri-close-line text-white",
              onClick: ($event: any) => (_ctx.removeValue(i)),
              role: "button"
            }, null, 8, _hoisted_8)
          ]))
        }), 128))
      ]))
}