
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter, useRoute } from "vue-router";
import { ElMessage } from "element-plus";
import { Field, Form } from "vee-validate";
import VueHtml2pdf from "vue3-html2pdf";
import MemberFile from "./docs/MemberFile.vue";
import Nomination from "./docs/Nomination.vue";
import { object, string } from "yup";
import membersApi from "@/core/services/MembersApi";
import { hideModal } from "@/core/helpers/dom";
import NewFilters from "./crafted/widgets/NewFilters.vue";
import PromotionsApi from "@/core/services/PromotionsApi";
import moment from "moment";

export default defineComponent({
    name: "MembersList",
    components: {
        Field,
        Form,
        VueHtml2pdf,
        MemberFile,
        Nomination,
        NewFilters,
    },
    data() {
        return {
            past_year: new Date().getFullYear() - 1,
            current_year: new Date().getFullYear(),
            next_year: new Date().getFullYear() + 1,
            current_month: new Date().getMonth() + 1,
            tagsKey: 0,
            filtersKey: 0,
            activeFilters: {},
            filtersPayload: {},
            filtersToShow: {
                age: true,
                bailliage: true,
                commander: true,
                country: true,
                dues: true,
                function: true,
                gender: true,
                grade: true,
                medal: true,
                member_type: true,
                other_association: true,
                period: true,
                postal_code: true,
                state: true,
                title: true,
                activity_sector: true,
                plaque: true,
                credit_card: true,
                institution_type: true,
                food_style: true,
                stars: true,
                price_category_promotion: false,
                price_category_due: false,
                type_due: false,
                relevant_due_year: true,
                type_chapitre: false,
                amount_accr: false,
                type_accr: false,
                biller_period: false,
                biller_balances: false,
            },
            members: [] as any,
            allMembersSelected: false,
            membersTh: [
                {
                    label: "ID",
                    class: "min-w-130px",
                    colName: "id",
                },
                {
                    label: "Name",
                    class: "min-w-130px",
                    colName: "lastname",
                },
                {
                    label: "Grade",
                    class: "min-w-100px",
                    colName: "grade",
                },
                {
                    label: "Bailliage",
                    class: "min-w-150px",
                    colName: "national_bailliage",
                },
                {
                    label: "Status",
                    class: "min-w-100px",
                    colName: "is_pro_omgd",
                },
                {
                    label: "Last due",
                    class: "min-w-125px",
                    colName: "last_due",
                },
            ],
            selectedMembers: [] as any,
            options: [
                {
                    label: "Before",
                    value: "before",
                },
                {
                    label: "After",
                    value: "after",
                },
                {
                    label: "At",
                    value: "at",
                },
                {
                    label: "Between",
                    value: "between",
                },
            ],
            selectedValue: "before",
            periodFrom: "",
            periodTo: "",
            membersSearch: "",
            pages: [] as any,
            loading: true,
            actualFilter: "",
            actualCol: "",
            filterGradeSearch: "",
            currentPage: this.savedPage ? this.savedPage : (1 as any),
            membersCount: 0,
            totalPages: 0,
            archiveReason: "",
            archiveReasons: [
                {
                    label: "Deceased",
                    value: "deceased",
                },
                {
                    label: "Terminated",
                    value: "terminated",
                },
            ],
            memberIdToArchive: "",
            perm_pos: "",
            exportProgressMsg: "",
            exportDialog: false,
            showSpinner: true,
            memberFileKey: 0,
            memberFileValues: [] as any,
            nominationKey: 0,
            nominationValues: [] as any,
            progressDialog: false,
            percentage: 0,
            loadExport: false,
            allChecked: false,
            selectionScope: "add",
            selectionPayload: {} as any,
        };
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const resultsPerPage = 10;
        const archiveModalRef = ref<null | HTMLElement>(null) as any;
        var savedPage: any;

        const htmlToPdfOptions = {
            margin: [10, 0, 11, 0],
            filename: "export_member.pdf",
        };

        function createDebounce() {
            let timeout: any;
            return function (fnc, delayMs) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    fnc();
                }, delayMs || 500);
            };
        }

        if (route.params.page) {
            var temp = route.params.page;
            if (temp) savedPage = +temp;
        }

        const closeArchiveModal = () => {
            hideModal(archiveModalRef.value);
        };

        const archiveSchema = object().shape({
            archiveReason: string()
                .required()
                .label("Archive reason")
                .nullable(),
        });

        onMounted(() => {
            setCurrentPageBreadcrumbs("Members list", []);
        });

        return {
            closeArchiveModal,
            archiveSchema,
            router,
            route,
            savedPage,
            archiveModalRef,
            debounce: createDebounce(),
            htmlToPdfOptions,
            resultsPerPage,
        };
    },
    mounted() {
        this.perm_pos = localStorage.getItem("perm_pos") as any;
        if (localStorage.getItem("activeFilters")) {
            // this.filtersPayload = JSON.parse(localStorage.getItem("filtersPayload") as any);
            this.activeFilters = JSON.parse(
                localStorage.getItem("activeFilters") as any
            );
            // this.tagsKey++;
            setTimeout(() => {
                this.filtersKey++;
            }, 50);
            this.getMembers();
        } else {
            this.getMembers();
        }
    },
    methods: {
        handleSelection(scope: string, event: any, member?: any) {
            if (scope === "top") {
                event.target.checked
                    ? (this.allChecked = true)
                    : (this.allChecked = false);
            } else if (scope === "single") {
                this.allChecked
                    ? (this.selectionScope = "remove")
                    : (this.selectionScope = "add");
                if (event.target.checked) {
                    this.allChecked
                        ? this.selectedMembers.splice(
                              this.selectedMembers.indexOf(member.id),
                              1
                          )
                        : this.selectedMembers.push(member.id);
                } else {
                    this.allChecked
                        ? this.selectedMembers.push(member.id)
                        : this.selectedMembers.splice(
                              this.selectedMembers.indexOf(member.id),
                              1
                          );
                }
            }

            this.selectionPayload = {
                type: this.selectionScope,
                ids: this.selectedMembers,
            };
        },
        exportMemberCards(ids: any) {
            var payload = {} as any;

            // On check s'il y a des filtres
            if (this.hasAnyFilters()) {
                payload = {
                    status: "active",
                    filters: this.filtersPayload,
                };
            } else {
                // Sinon on affiche tout
                payload = {
                    status: "active",
                };
            }

            if (Object.keys(this.selectionPayload).length !== 0) {
                payload = { ...payload, selection: this.selectionPayload };
            }

            // Si une recherche est faite
            if (this.membersSearch) {
                payload = { ...payload, query: this.membersSearch };
            }

            membersApi
                .exportMemberCards(payload)
                .then((res: any) => {
                    if (!res.success && res.status === 400)
                        if (ids)
                            ElMessage({
                                type: "info",
                                message: "This member has no due up to date.",
                            });
                        else
                            ElMessage({
                                type: "info",
                                message: "No due up to date.",
                            });
                    if (res.success)
                        window
                            .open(res.data.member_cards_zip, "_blank")
                            ?.focus();
                })
                .catch((err: any) => {
                    console.log(err);
                });
        },
        exportDoc(scope: string, year: any, id?: any) {
            var payload = {};

            if (scope !== "member_file") {
                if (id) {
                    payload = {
                        type: scope,
                        member: id,
                    };
                } else {
                    payload = {
                        selection: this.selectionPayload,
                        type: scope,
                        year: year,
                    };
                }
            } else {
                if (id) {
                    payload = {
                        type: scope,
                        member: id,
                    };
                } else {
                    payload = {
                        type: scope,
                        selection: this.selectionPayload,
                    };
                }
            }

            this.loadExport = true;

            membersApi.exportPDF(payload).then((res: any) => {
                this.loadExport = false;
                if (res.success) {
                    var count = 0;

                    if (res.data.nomination_chaine_pdf)
                        window
                            .open(res.data.nomination_chaine_pdf, "_blank")
                            ?.focus();
                    else count++;
                    if (res.data.nomination_omgd_pdf)
                        window.open(res.data.nomination_omgd_pdf, "_blank");
                    else count++;
                    if (res.data.promotion_pdf)
                        window.open(res.data.promotion_pdf, "_blank");
                    else count++;
                    if (res.data.member_file_pdf)
                        window.open(res.data.member_file_pdf, "_blank");
                    else count++;

                    if (count === 4) {
                        ElMessage({
                            type: "error",
                            message: "No data to export.",
                        });
                    }
                }
            });
        },
        handleSizeChange() {
            this.getMembers();
        },
        downloadPDF(pdf: string, id?: number) {
            this.progressDialog = true;

            const fetchMembers = new Promise((resolve, reject) => {
                this.memberFileValues = [];
                this.selectedMembers.map((id: any) => {
                    membersApi.getSpecificMember(id).then((res: any) => {
                        var payload = {
                            is_pro: res.data.is_pro,
                            title: res.data.title ? res.data.title.label : "",
                            firstname: res.data.userprofile.firstname,
                            lastname: res.data.userprofile.lastname,
                            birthdate: res.data.birthdate,
                            nationality: res.data.nationality
                                ? res.data.nationality.label
                                : "",
                            code: res.data.code,
                            profession: res.data.profession,
                            activity_sector: res.data.activity_sector
                                ? res.data.activity_sector.label
                                : "",
                            function: res.data.function
                                ? res.data.function.label
                                : "",
                            grade: res.data.grade ? res.data.grade.label : "",
                            national_bailliage: res.data.userprofile
                                .national_bailliage
                                ? res.data.userprofile.national_bailliage.label
                                : "",
                            regional_bailliage: res.data.userprofile
                                .regional_bailliage
                                ? res.data.userprofile.regional_bailliage.label
                                : "",
                            provincial_bailliage: res.data.userprofile
                                .provincial_bailliage
                                ? res.data.userprofile.provincial_bailliage
                                      .label
                                : "",
                            sponsors: [res.data.sponsor1, res.data.sponsor2],
                            contact: {
                                address: res.data.address
                                    ? res.data.address.address
                                    : "",
                                city: res.data.address
                                    ? res.data.address.city
                                    : "",
                                zip: res.data.address
                                    ? res.data.address.postal_code
                                    : "",
                                country: res.data.address
                                    ? res.data.address.country
                                    : "",
                                phone: res.data.contact
                                    ? res.data.contact.phone
                                    : "",
                                fax: "?",
                                mobile: res.data.contact
                                    ? res.data.contact.mobile
                                    : "",
                                email: res.data.contact
                                    ? res.data.contact.email
                                    : "",
                            },
                            contact_pro: {
                                address: res.data.is_pro
                                    ? res.data.institution.address.address
                                    : "",
                                city: res.data.is_pro
                                    ? res.data.institution.address.city
                                    : "",
                                zip: res.data.is_pro
                                    ? res.data.institution.address.postal_code
                                    : "",
                                country: res.data.is_pro
                                    ? res.data.institution.address.country
                                    : "",
                                phone: res.data.is_pro
                                    ? res.data.contact_pro.phone
                                    : "",
                                fax: "?",
                                mobile: res.data.is_pro
                                    ? res.data.contact_pro.mobile
                                    : "",
                                email: res.data.is_pro
                                    ? res.data.contact_pro.email
                                    : "",
                                website: "?",
                            },
                        } as any;

                        membersApi
                            .getMemberDues({ p: 1, presult: 9999, member: id })
                            .then((duesRes: any) => {
                                var duesPayload = [] as any;
                                duesRes.data.cotisation_list.map((due: any) => {
                                    duesPayload.push({
                                        id: due.id,
                                        type: due.type.label,
                                        year: due.relevant_year,
                                        date: due.date,
                                    });
                                });
                                payload = { ...payload, dues: duesPayload };
                                PromotionsApi.getPromotions({
                                    p: 1,
                                    presult: 9999,
                                    member: id,
                                }).then((promotionsRes: any) => {
                                    var promotionsPayload = [] as any;
                                    promotionsRes.data.promotion_list.map(
                                        (promotion: any) => {
                                            promotionsPayload.push({
                                                id: promotion.id,
                                                type: promotion.type.label,
                                                date: promotion.date,
                                                grade: promotion.grade.label
                                                    ? promotion.grade.label
                                                    : "",
                                                intronisation:
                                                    promotion.intronisation_date
                                                        ? promotion.intronisation_date
                                                        : "",
                                                country:
                                                    promotion.intronisation_location
                                                        ? promotion.intronisation_location
                                                        : "",
                                                city: "",
                                            });
                                        }
                                    );
                                    payload = {
                                        ...payload,
                                        promotions: promotionsPayload,
                                    };
                                    this.memberFileValues.push(payload);
                                });
                            });
                    });
                });
                setTimeout(() => {
                    resolve(this.memberFileValues);
                }, 3000);
            });

            const preparePromotion = new Promise((resolve, reject) => {
                if (pdf === "promotion") {
                    this.nominationValues = [];
                    membersApi.getSpecificMember(id).then((res: any) => {
                        this.nominationValues.push({
                            type: "Promotion",
                            title: res.data.title ? res.data.title.label : "",
                            firstname: res.data.userprofile.firstname,
                            lastname: res.data.userprofile.lastname,
                            desc_before_grade:
                                "Nous avons l’honneur de vous faire savoir qu’après examen de votre dossier, nous avons décidé de vous promouvoir au grade de",
                            desc_after_grade:
                                "Suivant notre règlement, vous recevrez officiellement, lors d’un prochain Chapitre de votre choix, les insignes de votre nouveau grade.",
                            politeness:
                                "Nous sommes heureux de vous adresser nos vives félicitations et nos salutations les plus confraternelles.",
                            grade: res.data.grade ? res.data.grade : "-",
                            bailliage: {
                                national: res.data.userprofile
                                    .national_bailliage
                                    ? res.data.userprofile.national_bailliage
                                          .label
                                    : "",
                                regional: res.data.userprofile
                                    .regional_bailliage
                                    ? res.data.userprofile.regional_bailliage
                                          .label
                                    : "",
                            },
                            city: "Paris",
                            date: moment().locale("fr").format("D MMMM YYYY"),
                            omgd: false,
                        });
                        setTimeout(() => {
                            resolve(this.nominationValues);
                        }, 3000);
                    });
                }
            });

            const prepareNomination = new Promise((resolve, reject) => {
                if (pdf === "nomination") {
                    this.nominationValues = [];
                    membersApi.getSpecificMember(id).then((res: any) => {
                        this.nominationValues.push({
                            type: "Nomination",
                            title: res.data.title ? res.data.title.label : "",
                            firstname: res.data.userprofile.firstname,
                            lastname: res.data.userprofile.lastname,
                            desc_before_grade:
                                "Nous avons l’honneur de vous faire savoir qu’après examen de votre dossier, nous avons décidé de votre admission au sein de la Chaîne des Rôtisseurs au grade de",
                            desc_after_grade:
                                "Suivant notre règlement, vous recevrez officiellement, lors d’un prochain Chapitre de votre choix, les insignes de votre grade. Le diplôme vous sera remis lors de votre intronisation.",
                            politeness:
                                "Très honorés de vous compter parmi nous, nous vous prions de bien vouloir agréer l’expression de nos sentiments dévoués.",
                            grade: res.data.grade ? res.data.grade : "-",
                            bailliage: {
                                national: res.data.userprofile
                                    .national_bailliage
                                    ? res.data.userprofile.national_bailliage
                                          .label
                                    : "",
                                regional: res.data.userprofile
                                    .regional_bailliage
                                    ? res.data.userprofile.regional_bailliage
                                          .label
                                    : "",
                            },
                            city: "Paris",
                            date: moment().locale("fr").format("D MMMM YYYY"),
                            omgd: res.data.is_omgd ? true : false,
                        });
                        setTimeout(() => {
                            resolve(this.nominationValues);
                        }, 3000);
                    });
                }
            });

            switch (pdf) {
                case "member_file":
                    this.memberFileValues = [];
                    membersApi.getSpecificMember(id).then((res: any) => {
                        var payload = {
                            is_pro: res.data.is_pro,
                            title: res.data.title ? res.data.title.label : "",
                            firstname: res.data.userprofile.firstname,
                            lastname: res.data.userprofile.lastname,
                            birthdate: res.data.birthdate,
                            nationality: res.data.nationality
                                ? res.data.nationality.label
                                : "",
                            code: res.data.code,
                            profession: res.data.profession,
                            activity_sector: res.data.activity_sector
                                ? res.data.activity_sector.label
                                : "",
                            function: res.data.function
                                ? res.data.function.label
                                : "",
                            grade: res.data.grade,
                            national_bailliage: res.data.userprofile
                                .national_bailliage
                                ? res.data.userprofile.national_bailliage.label
                                : "",
                            regional_bailliage: res.data.userprofile
                                .regional_bailliage
                                ? res.data.userprofile.regional_bailliage.label
                                : "",
                            provincial_bailliage: res.data.userprofile
                                .provincial_bailliage
                                ? res.data.userprofile.provincial_bailliage
                                      .label
                                : "",
                            sponsors: [res.data.sponsor1, res.data.sponsor2],
                            contact: {
                                address: res.data.address
                                    ? res.data.address.address
                                    : "",
                                city: res.data.address
                                    ? res.data.address.city
                                    : "",
                                zip: res.data.address
                                    ? res.data.address.postal_code
                                    : "",
                                country: res.data.address
                                    ? res.data.address.country
                                    : "",
                                phone: res.data.contact
                                    ? res.data.contact.phone
                                    : "",
                                fax: "?",
                                mobile: res.data.contact
                                    ? res.data.contact.mobile
                                    : "",
                                email: res.data.contact
                                    ? res.data.contact.email
                                    : "",
                            },
                            contact_pro: {
                                address: res.data.is_pro
                                    ? res.data.institution.address.address
                                    : "",
                                city: res.data.is_pro
                                    ? res.data.institution.address.city
                                    : "",
                                zip: res.data.is_pro
                                    ? res.data.institution.address.postal_code
                                    : "",
                                country: res.data.is_pro
                                    ? res.data.institution.address.country
                                    : "",
                                phone: res.data.is_pro
                                    ? res.data.contact_pro.phone
                                    : "",
                                fax: "?",
                                mobile: res.data.is_pro
                                    ? res.data.contact_pro.mobile
                                    : "",
                                email: res.data.is_pro
                                    ? res.data.contact_pro.email
                                    : "",
                                website: "?",
                            },
                        } as any;

                        membersApi
                            .getMemberDues({ p: 1, presult: 9999, member: id })
                            .then((duesRes: any) => {
                                var duesPayload = [] as any;
                                duesRes.data.cotisation_list.map((due: any) => {
                                    duesPayload.push({
                                        id: due.id,
                                        type: due.type.label,
                                        year: due.relevant_year,
                                        date: due.date,
                                    });
                                });
                                payload = { ...payload, dues: duesPayload };
                                PromotionsApi.getPromotions({
                                    p: 1,
                                    presult: 9999,
                                    member: id,
                                }).then((promotionsRes: any) => {
                                    var promotionsPayload = [] as any;
                                    promotionsRes.data.promotion_list.map(
                                        (promotion: any) => {
                                            promotionsPayload.push({
                                                id: promotion.id,
                                                type: promotion.type.label,
                                                date: promotion.date,
                                                grade: promotion.current_grade
                                                    ? promotion.current_grade
                                                    : "",
                                                intronisation:
                                                    promotion.intronisation_date
                                                        ? promotion.intronisation_date
                                                        : "",
                                                country:
                                                    promotion.intronisation_location
                                                        ? promotion.intronisation_location
                                                        : "",
                                                city: "",
                                            });
                                        }
                                    );
                                    payload = {
                                        ...payload,
                                        promotions: promotionsPayload,
                                    };
                                    this.memberFileValues.push(payload);
                                });
                            });
                    });
                    setTimeout(() => {
                        this.memberFileKey++;
                        (this.$refs.member_file as any).generatePdf();
                    }, 1000);
                    break;
                case "member_files":
                    fetchMembers.then((response) => {
                        this.memberFileKey++;
                        (this.$refs.member_file as any).generatePdf();
                    });
                    break;
                case "promotion":
                    preparePromotion.then((response) => {
                        this.nominationKey++;
                        (this.$refs.promotion as any).generatePdf();
                    });
                    break;
                case "nomination":
                    prepareNomination.then((response) => {
                        this.nominationKey++;
                        (this.$refs.promotion as any).generatePdf();
                    });
                    break;
            }
        },
        setProgress(e: any) {
            this.percentage = e;

            if (this.percentage === 100) {
                setTimeout(() => {
                    this.progressDialog = false;
                    this.percentage = 0;
                }, 1000);
            }
        },
        exportMembers() {
            this.exportDialog = this.showSpinner = true;
            this.exportProgressMsg =
                "Retrieving data from the " +
                this.membersCount +
                " selected members...";
            var payload;

            // On check s'il y a des filtres
            if (this.hasAnyFilters()) {
                if (this.actualFilter && this.actualCol) {
                    // Si filtres & tri
                    payload = {
                        status: "active",
                        filters: this.activeFilters,
                        column: this.actualCol,
                        order: this.actualFilter,
                    };
                } else {
                    // Si filtres et pas de tri
                    payload = {
                        status: "active",
                        filters: this.activeFilters,
                    };
                }
            } else if (this.actualFilter && this.actualCol) {
                // Si tri et pas de filtres
                payload = {
                    status: "active",
                    column: this.actualCol,
                    order: this.actualFilter,
                };
            } else {
                // Sinon on affiche tout
                payload = {
                    status: "active",
                };
            }

            if (this.membersSearch) {
                payload = { ...payload, query: this.membersSearch };
            }

            if (Object.keys(this.selectionPayload).length !== 0) {
                payload = { ...payload, selection: this.selectionPayload };
            }

            membersApi.exportMembers(payload).then((res: any) => {
                this.exportDialog = this.showSpinner = false;
                if (res.success) {
                    window.open(res.data.member_export, "_blank")?.focus();
                }
            });
            console.log(payload);
        },
        redirectTo(destination: any, id: any) {
            if (destination === "promotions") {
                const routeData = this.router.resolve({
                    name: "member-details",
                    params: { editPromotionId: "edit", member_id: id },
                });
                window.open(routeData.href, "_blank");
            } else if (destination === "dues") {
                const routeData = this.router.resolve({
                    name: "member-details",
                    params: { editDueId: "edit", member_id: id },
                });
                window.open(routeData.href, "_blank");
            }
        },
        getFiltersPayload(e: any) {
            // console.log("event", e);

            this.tagsKey++;
            this.activeFilters = e[1];

            var f = JSON.stringify(this.activeFilters);

            localStorage.setItem("activeFilters", f);

            this.getMembers();
        },
        hasAnyFilters() {
            if (this.activeFilters) {
                for (const [key, value] of Object.entries(this.activeFilters)) {
                    if (value !== "") return true;
                }
                return false;
            }
        },
        archiveMember(reason: string, id: number) {
            var payload;
            var membersIdToArchive = [] as any;

            this.loading = true;

            if (id) {
                membersIdToArchive.push(id);
            } else {
                membersIdToArchive = this.selectedMembers;
            }

            payload = {
                members: membersIdToArchive,
                status: reason,
            };

            membersApi.archiveMembers(payload).then((res) => {
                this.closeArchiveModal();
                // If we delete all the entries on the last page, we need to go back to the previous page
                if (
                    this.currentPage === this.totalPages &&
                    this.selectedMembers.length === this.members.length
                ) {
                    this.currentPage = this.currentPage - 1;
                }
                var topCheckbox = document.getElementById("topCheckbox") as any;
                topCheckbox.checked = false;
                this.getMembers();
                ElMessage({
                    type: "info",
                    message: "Member(s) archived.",
                });
            });
        },
        editMember(member: any, newTab?: boolean) {
            if (newTab) {
                const routeData = this.router.resolve({
                    name: "member-details",
                    params: { member_id: member.id, page: this.currentPage },
                });
                window.open(routeData.href, "_blank");
            } else {
                this.router.push({
                    name: "member-details",
                    params: { member_id: member.id, page: this.currentPage },
                });
            }
        },
        getMembers() {
            var payload;
            this.loading = true;
            //this.selectedMembers = [];
            this.members = [];

            // On check s'il y a des filtres
            if (this.hasAnyFilters()) {
                if (this.actualFilter && this.actualCol) {
                    // Si filtres & tri
                    payload = {
                        p: this.currentPage,
                        presult: this.resultsPerPage,
                        status: "active",
                        filters: this.activeFilters,
                        column: this.actualCol,
                        order: this.actualFilter,
                    };
                } else {
                    // Si filtres et pas de tri
                    payload = {
                        p: this.currentPage,
                        presult: this.resultsPerPage,
                        status: "active",
                        filters: this.activeFilters,
                    };
                }
            } else if (this.actualFilter && this.actualCol) {
                // Si tri et pas de filtres
                payload = {
                    p: this.currentPage,
                    presult: this.resultsPerPage,
                    status: "active",
                    column: this.actualCol,
                    order: this.actualFilter,
                };
            } else {
                // Sinon on affiche tout
                payload = {
                    p: this.currentPage,
                    presult: this.resultsPerPage,
                    status: "active",
                };
            }

            if (this.membersSearch) {
                payload = { ...payload, query: this.membersSearch };
            }

            membersApi.getMembers(payload).then((res: any) => {
                res.data.member_list.map((member: any) => {
                    this.members.push(member);
                });
                this.membersCount = res.data.member_count;
                this.totalPages = res.data.page_count;
                this.loading = false;
            });
        },
        sortColumn(column: string, id: number) {
            var arrows = document.getElementById("chevrons" + id);

            // Hide all arrows
            Array.from(
                document.getElementsByClassName("chevrons-container") as any
            ).map((filter: any) => {
                filter.style.display = "none";
            });

            // Display the right arrows
            if (arrows) arrows.style.display = "flex";

            // If we click for the first time on a column OR if we change the column
            if (this.actualCol === "" || this.actualCol !== column) {
                this.actualCol = column;
                this.actualFilter = "asc";
                this.handleChevron("show", "up");
                this.handleChevron("hide", "down");

                // Else if we click on the same column
            } else if (this.actualCol === column) {
                if (this.actualFilter === "asc") {
                    this.handleChevron("hide", "up");
                    this.handleChevron("show", "down");
                    this.actualFilter = "desc";
                } else if (this.actualFilter === "desc") {
                    this.handleChevron("show", "up");
                    this.handleChevron("hide", "down");
                    this.actualFilter = "asc";
                }
            }
            this.getMembers();
        },
        handleChevron(action: string, direction: string) {
            var chevrons: any;

            if (direction === "up")
                chevrons = Array.from(
                    document.getElementsByClassName("fa-chevron-up") as any
                );
            else if (direction === "down")
                chevrons = Array.from(
                    document.getElementsByClassName("fa-chevron-down") as any
                );

            if (action === "show")
                chevrons.map((chevron: any) => {
                    chevron.classList.add("active-chevron");
                });
            else if (action === "hide")
                chevrons.map((chevron: any) => {
                    chevron.classList.remove("active-chevron");
                });
        },
        checkAll() {
            var topCheckbox = document.getElementById("topCheckbox") as any;
            this.allMembersSelected = !this.allMembersSelected;
            this.selectedMembers = [];
            topCheckbox.checked
                ? (this.selectionScope = "remove")
                : (this.selectionScope = "add");
        },
        handleActionSelect(member: any) {
            var focusedCheckbox = document.getElementById(
                "checkbox" + member.id
            ) as any;

            // if (focusedCheckbox?.checked) {
            //     this.selectedMembers.push(member.id);
            // } else {
            //     this.selectedMembers.map((localMember: any) => {
            //         if (member.id === localMember) {
            //             this.selectedMembers.splice(this.selectedMembers.indexOf(localMember), 1);
            //         }
            //     });
            // }
        },
        handlePagination(pageNumber: any) {
            //this.selectedMembers = [];
            this.savedPage = undefined;
            // var topCheckbox = document.getElementById("topCheckbox") as any;
            // topCheckbox.checked = false;

            // console.log(
            //     "selected",
            //     this.selectedMembers,
            //     this.allMembersSelected
            // );

            this.currentPage = pageNumber;
            this.getMembers();
        },
        getMemberTypeBadge(memberType: any) {
            switch (memberType.label) {
                case "pro":
                    return "badge-dark";
                case "omgd":
                    return "badge-success";
                case "terminated":
                    return "badge-terminated";
                default:
                    return "";
            }
        },
        formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
        },
        queryChanged(query: string) {
            this.membersSearch = query;
            this.getMembers();
        },
    },
});
