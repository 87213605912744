
import { defineComponent } from "vue";

export default defineComponent({
    name: "filterTags",
    props: {
        data_name: String,
        data: Array,
        complex: Boolean,
        linkWord: String, // can be "in", "and" or "-"
    },
    emits: ["removedTag"],
    components: {},
    data() {
        return {
            values: this.$props.data as any,
            scope: this.$props.data_name as any,
            isComplex: this.$props.complex as any,
            link: this.$props.linkWord as any,
        };
    },
    methods: {
        checkIsArray(e) {
            return Array.isArray(e);
        },
        removeValue(index) {
            this.values.splice(index, 1);
            this.$emit("removedTag", { scope: this.scope, values: this.values, complex: this.isComplex });
        },
    },
});
