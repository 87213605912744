
import { defineComponent } from "vue";
import membersApi from "@/core/services/MembersApi";
import NewFilterTags from "./NewFilterTags.vue";

export default defineComponent({
    emits: ["queryChanged", "filtersSet"],
    name: "new_filters",
    components: { NewFilterTags },
    props: {
        initialFilters: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            // v-model for inputs (should only contains ids)
            filters: {
                dues_relevant_year: [] as any,
                dues_date: [] as any,
                ages: [] as any,
            } as any,
            // ------------------

            // selected values (= clicked options) by user (should contains full objects)
            values: {
                national_bailliages: [] as any,
                provincial_bailliages: [] as any,
                regional_bailliages: [] as any,
                commandeurs: [] as any,
                commandeurs_eligible: [] as any,
                dues_relevant_year: [] as any,
                dues_date: [] as any,
                grades: [] as any,
                grades_conseil: [] as any,
                grades_eligible: [] as any,
                bureau: [] as any,
                medals: [] as any,
                member_types: [] as any,
                associations: [] as any,
                competitions: [] as any,
                is_uptodate: [] as any,
                gender: "" as any,
                ages: [] as any,
                children_over_18: [] as any,
                children_in_chaine: [] as any,
            } as any,
            // ------------------

            querySearch: "",
            filtersKey: 0,
            filtersDrawer: false,
            childrenBailliagesLoaded: false,
            nBailliages: [] as any,
            pBailliages: [] as any,
            rBailliages: [] as any,
            commandeurs: [] as any,
            grades: [] as any,
            grades_conseil: [] as any,
            medals: [] as any,

            generic_options: [
                {
                    id: "at",
                    label: "At",
                },
                {
                    id: "before",
                    label: "Before",
                },
                {
                    id: "between",
                    label: "Between",
                },
                {
                    id: "after",
                    label: "After",
                },
            ],
            ages_options: [
                {
                    id: "equals_to",
                    label: "Is",
                },
                {
                    id: "less_than",
                    label: "Less than",
                },
                {
                    id: "more_than",
                    label: "More than",
                },
                {
                    id: "between",
                    label: "Between",
                },
            ],
            bureaux: [
                {
                    id: 0,
                    label: "National",
                },
                {
                    id: 1,
                    label: "Provincial",
                },
                {
                    id: 2,
                    label: "Regional",
                },
            ],
            types: [
                { id: 0, label: "Amateur", value: "amateur" },
                { id: 1, label: "Pro", value: "pro" },
            ],
            associations: [
                { id: 0, label: "CDR", value: "cdr" },
                { id: 1, label: "OMGD", value: "omgd" },
            ],
            competitions: [
                { id: 0, label: "JCR", value: "jcr" },
                { id: 1, label: "JS", value: "js" },
            ],

            // temp variables
            temp_commandeurs_eligible: "",
            temp_dues_relevant_year_period: "",
            temp_dues_date: "",
            temp_grades: "",
            temp_conseils: "",
            temp_ages_period: "",
        };
    },
    setup() {
        function createDebounce() {
            let timeout: any;
            return function (fnc, delayMs) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    fnc();
                }, delayMs || 500);
            };
        }

        return { debounce: createDebounce() };
    },
    mounted() {
        this.getData();
        if (localStorage.getItem("activeFilters")) {
            // this.filters = this.initialFilters;
            const filtersJSON = localStorage.getItem("filters");
            if (filtersJSON && filtersJSON.trim() !== "") {
                this.values = JSON.parse(filtersJSON);
            }
        }
    },
    methods: {
        getChildrenBailliages(ids: any) {
            if (ids.length) {
                membersApi
                    .getMultipleChildrenBailliages({ national_bailliages: ids })
                    .then((res) => {
                        var bailliages = res.data;
                        this.pBailliages = [];
                        this.rBailliages = [];
                        bailliages.map((bailliage: any) => {
                            if (bailliage.type === "Provincial")
                                this.pBailliages.push(bailliage);
                            if (bailliage.type === "Regional")
                                this.rBailliages.push(bailliage);
                        });
                        this.childrenBailliagesLoaded = true;
                    });
            } else {
                this.clearFilter("provincial_bailliages");
                this.clearFilter("regional_bailliages");
                this.childrenBailliagesLoaded = false;
            }
        },
        applyFilters() {
            localStorage.setItem("filters", JSON.stringify(this.values));

            const finalFilters = {} as any;
            if (Object.keys(this.values).length > 0) {
                Object.keys(this.values).forEach((key) => {
                    if (
                        Array.isArray(this.values[key]) &&
                        this.values[key] &&
                        this.values[key].length > 0
                    )
                        finalFilters[key] = this.values[key];
                    else if (!Array.isArray(this.values[key]))
                        finalFilters[key] = this.values[key];
                });
            }
            this.buildFilters(finalFilters).then((res) => {
                this.$emit("filtersSet", [{}, res]);
                this.filtersDrawer = false;
            });
        },
        async buildFilters(e: any) {
            // console.log(e);

            const keys_to_format = [
                "national_bailliages",
                "provincial_bailliages",
                "regional_bailliages",
                "commandeurs",
                "grades",
                "grades_eligible",
                "medals",
            ];

            let member_types = {
                type:
                    this.filters.member_types &&
                    this.filters.member_types.length
                        ? this.filters.member_types
                        : null,
                association:
                    this.filters.associations &&
                    this.filters.associations.length
                        ? this.filters.associations
                        : null,
                competition:
                    this.filters.competitions &&
                    this.filters.competitions.length
                        ? this.filters.competitions
                        : null,
            } as any;

            const children = {
                children_in_chaine:
                    e.children_in_chaine &&
                    e.children_in_chaine.some((value: any) => value === "yes")
                        ? true
                        : false,
                children_over_18:
                    e.children_over_18 &&
                    e.children_over_18.some((value: any) => value === "yes")
                        ? true
                        : false,
            };

            return new Promise((resolve) => {
                keys_to_format.forEach((key) => {
                    if (e[key] && e[key].length > 0) {
                        e[key] = e[key].map((value: any) => {
                            return value.id;
                        });
                    }
                });

                if (e.is_uptodate && e.is_uptodate.length > 0) {
                    if (e.is_uptodate.some((value: any) => value === "yes"))
                        e.is_uptodate = true;
                    else e.is_uptodate = false;
                }

                if (
                    !member_types.type &&
                    !member_types.association &&
                    !member_types.competition
                ) {
                    member_types = null;
                }

                delete e.member_types;
                delete e.associations;
                delete e.competitions;
                delete e.children_in_chaine;
                delete e.children_over_18;

                const finalPayload = {
                    ...e,
                    ...children,
                    member_types: member_types ? member_types : null,
                };

                if (finalPayload.member_types === null)
                    delete finalPayload.member_types;
                // console.log("eheh", finalPayload);
                resolve(finalPayload);
            });
        },
        resetFilters() {
            localStorage.removeItem("filters");
            localStorage.removeItem("activeFilters");
            this.filters = {
                dues_relevant_year: [] as any,
                dues_date: [] as any,
                ages: [] as any,
            } as any;
            this.values = {
                national_bailliages: [] as any,
                provincial_bailliages: [] as any,
                regional_bailliages: [] as any,
                commandeurs: [] as any,
                commandeurs_eligible: [] as any,
                dues_relevant_year: [] as any,
                dues_date: [] as any,
                grades: [] as any,
                grades_conseil: [] as any,
                grades_eligible: [] as any,
                bureau: [] as any,
                medals: [] as any,
                member_types: [] as any,
                associations: [] as any,
                competitions: [] as any,
                is_uptodate: [] as any,
                gender: "" as any,
                ages: [] as any,
                children_over_18: [] as any,
                children_in_chaine: [] as any,
            } as any;
            this.filtersKey++;
            this.filtersDrawer = false;
            this.$emit("filtersSet", [{}, {}]);
        },
        setTempVar(scope: string, option: any) {
            this["temp_" + scope] = option.label;
        },
        buildFilterTag(scope: Array<string>, complex?: boolean) {
            if (!complex) {
                this.values[scope[0]].push({
                    id: this.filters[scope[0]],
                    value: this["temp_" + scope[0]],
                    date: this.filters[scope[1]],
                });
                this.filters[scope[1]] = "";
            } else {
                this.values[scope[1]].push({
                    id: this.filters[scope[0]],
                    value: this["temp_" + scope[0]],
                    date: this.filters[scope[1]].map((value: any) => {
                        return value;
                    }),
                });
                this.filters[scope[1]] = [];
            }
            this.filters[scope[0]] = "";
            this["temp_" + scope[0]] = "";
        },
        updateTags(e: any) {
            this.values[e.scope] = e.values;
            if (!e.complex) {
                this.filters[e.scope] = [];
                e.values.map((value: any) => {
                    this.filters[e.scope].push(value.id);
                });
            }
        },
        clearFilter(scope: string) {
            this.values[scope] = this.filters[scope] = [];
            this.filtersKey++;
        },
        removeValue(scope: string, index: number) {
            this.values[scope].splice(index, 1);
            this.filters[scope].splice(index, 1);
        },
        addValue(scope: string, value_to_add: any, isSingle?: boolean) {
            const index = this.values[scope].findIndex(
                (v) => v.id === value_to_add.id
            );
            if (index !== -1) {
                this.values[scope].splice(index, 1);
            } else {
                if (!isSingle) this.values[scope].push(value_to_add);
                else this.values[scope] = value_to_add;
            }
        },
        handleQuery() {
            this.$emit("queryChanged", this.querySearch);
        },
        getData() {
            membersApi.getBailliages().then((res) => {
                var bailliages = res.data;
                bailliages.map((bailliage: any) => {
                    if (bailliage.type === "National")
                        this.nBailliages.push(bailliage);
                    if (bailliage.type === "Provincial")
                        this.pBailliages.push(bailliage);
                    if (bailliage.type === "Regional")
                        this.rBailliages.push(bailliage);
                });
            });
            membersApi
                .getCommanders({ p: 1, presult: 9999 })
                .then((res: any) => {
                    this.commandeurs = res.data.object_list;
                });
            membersApi.getGrades({ p: 1, presult: 9999 }).then((res: any) => {
                this.grades = res.data.object_list.sort((a: any, b: any) =>
                    a.label > b.label ? 1 : -1
                );
                this.grades_conseil = res.data.object_list
                    .filter((g: any) => g.is_conseil)
                    .sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
            });
            membersApi.getMedals({ p: 1, presult: 9999 }).then((res: any) => {
                this.medals = res.data.object_list;
            });
        },
    },
});
